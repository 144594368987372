import React from 'react'
import CartDropdownIcon from '../components/cartDropdownIcon'
import {bindActionCreators} from "redux";
import {addToCart, delCartItem} from "../actions/cartDropdowIconActions";
import {connect} from "react-redux";


class CartDropdownApp extends React.Component {

    constructor(props) {
      super(props);
      this.handleAddCartItem = this.handleAddCartItem.bind(this);
    }
    componentDidMount() {

        document.addEventListener("NcartAddItem", this.handleAddCartItem);
    }

    componentWillUnmount() {

        document.removeEventListener("NcartAddItem", this.handleAddCartItem);

    }

    handleAddCartItem(e) {

        this.props.addToCart(e.detail);

    }

    render() {
        return (
            <div>
                <CartDropdownIcon />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    dropdowncart: state.dropdowncart,
    dropdownitems: state.dropdownitems
})

const mapDispatchToProps = dispatch => bindActionCreators({ delCartItem, addToCart }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartDropdownApp)
