import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import ReactThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
import reducer from '../reducers'


export default function configureStore() {

  const middleware = [ ReactThunk, logger ];
  const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(...middleware),)
  )

  if (module.hot) {
    module.hot.accept('../reducers', () =>
      store.replaceReducer(require('../reducers').default)
    );
  }

  return store;
}
