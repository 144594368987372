import {SINGLETON_KEY} from '../constants'

export default class Singleton {
  constructor() {
    let instance = window[SINGLETON_KEY];
    if (!instance)
      instance = window[SINGLETON_KEY] = this;
    return instance;
  }
}
