import React from 'react'
import AddProductButton from './components/AddProductButton'
import {Provider} from 'react-redux'
//import './addproductbutton.css'

export default (props) => {
  const {store, attrs} = props;
  return (
      <Provider store={store}>
          <AddProductButton attrs={attrs}/>
      </Provider>
  )
}
