import Singleton from "./utils/singleton";
import React from 'react';
import {render} from 'react-dom';
import {default as configureCartStore} from './ncart/store/configureStore';
import {NCART_KEY} from './constants';
import CartDropdownApp from './ncart';
import CartMobileIcon from './ncart/components/cartMobileIcon';



document.addEventListener("DOMContentLoaded", function(event) {
  const ncartDropdownWidgetContainer = document.getElementById('ncartdropdown');
  const ncartMobileWidgetContainer = document.getElementById('ncartmobile');

  if (ncartDropdownWidgetContainer) {
    const globalStore = new Singleton();
    const cartStore = configureCartStore();
    let attrs = ncartDropdownWidgetContainer.attributes;
    if (!globalStore[NCART_KEY])
      globalStore[NCART_KEY] = cartStore;
      render(<CartDropdownApp store={cartStore} attrs={attrs}/>, ncartDropdownWidgetContainer);
      if (ncartMobileWidgetContainer){
        render(<CartMobileIcon store={cartStore}/>, ncartMobileWidgetContainer);
      }
  }

});

// enable hot module relaoding for the dev server
if (process.env.NODE_ENV === 'development')
  module.hot.accept();
