import { createStore, applyMiddleware } from 'redux';
import ReactThunk from 'redux-thunk'
import reducer from '../reducers';


export default function configureStore() {
  return createStore(
      reducer,
      applyMiddleware(ReactThunk)
  );
}
