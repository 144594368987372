import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import {setVisibleState, fetchCartItems, fetchInCart, delCartItem, addToCart} from '../actions/cartDropdowIconActions'
import CartDropdownProduct from './cartDropdownProduct'
import {bindActionCreators} from "redux";


const CartDropdownIcon  = props => {

    const {dropdowncart, dropdownitems, setVisibleState, fetchCartItems, fetchInCart, delCartItem} = props
    let className = 'ncartdropdown';
    className = (dropdowncart.status === 'CLOSED') ? (className += ' closed') : (className += ' opened');
    const hasProducts = dropdownitems.items.length > 0;

    const show_loader = (dropdownitems.status === "LOADING") ? true : false;
    const maxItems = 9;
    const cutItems = 5;
    const needCut = hasProducts && dropdownitems.items.length > maxItems;

    useEffect(() => {

        fetchInCart();

    }, [])

    useEffect(() => {

        if (dropdowncart.incart_status === "LOADED") {

            let inCartUpdatedEvent = new CustomEvent("NcartInCartUpdated", {detail: dropdowncart.incart});
            document.dispatchEvent(inCartUpdatedEvent);

        }

    }, [dropdowncart.incart])

    useEffect(() => {
        if (dropdowncart.status === "OPENED") {

            fetchCartItems();

        }
    }, [dropdowncart.status])


    const error_message = (dropdownitems.status === "ERROR") ? (
        <div className="ncart-error">Ошибка загрузки данных</div>
    ) : null

    const nodes = hasProducts ? (
        dropdownitems.items.map((item, index) =>
            <CartDropdownProduct
                title={item.title}
                total={item.total}
                unit={item.unit}
                unit_step={item.unit_step}
                quantity={item.quantity}
                link={item.link}
                key={item.id}
                delFunction={() => delCartItem(item.id)}
            />
        )
    ) : (
        <div className="ncart-empty">Ваша корзина пока пуста</div>
    )

    const wrapped_nodes = needCut ? (
        <>
            {dropdownitems.items.slice(0, (cutItems-1)).map((item, index) =>
            <CartDropdownProduct
                title={item.title}
                total={item.total}
                unit={item.unit}
                unit_step={item.unit_step}
                quantity={item.quantity}
                link={item.link}
                key={item.id}
                delFunction={() => delCartItem(item.id)}
            />
            )}
            <div className="ncart-product-item text-center">
                <span className="quantity w-100 pb-1">.....+{dropdownitems.items.length - 2 * cutItems + 2}......</span>
            </div>
            {dropdownitems.items.slice(dropdownitems.items.length - cutItems + 1).map((item, index) =>
            <CartDropdownProduct
                title={item.title}
                total={item.total}
                unit={item.unit}
                unit_step={item.unit_step}
                quantity={item.quantity}
                link={item.link}
                key={item.id}
                delFunction={() => delCartItem(item.id)}
            />
        )   }
        </>
    ): (nodes)

    const wrappe_nodes = needCut ? (
        function () {
            for (let i = 0; i < cutItems; i++) {
                let item = dropdownitems.items[i];
                <CartDropdownProduct
                    title={item.title}
                total={item.total}
                unit={item.unit}
                unit_step={item.unit_step}
                quantity={item.quantity}
                link={item.link}
                key={item.id}
                delFunction={() => delCartItem(item.id)}
            />
            }
        },
        <div className="ncart-product-item">
            <span className="quantity">{dropdownitems.items.length - 2*cutItems + 2}</span>&nbsp;<span className="title">...........</span>
        </div>,
        function () {for (let i = dropdownitems.items.length - cutItems + 1; i < dropdownitems.items.length; i++) {
            let item = dropdownitems.items[i];
            <CartDropdownProduct
                title={item.title}
                total={item.total}
                unit={item.unit}
                unit_step={item.unit_step}
                quantity={item.quantity}
                link={item.link}
                key={item.id}
                delFunction={() => delCartItem(item.id)}
            />
        }}) : (nodes)

    return (
        <div className={className}
             onMouseEnter={() => setVisibleState("OPENED")}
             onMouseLeave={() => setVisibleState("CLOSED")}>
            <div className="ncartdropdown-main">
                <a className="header-menu-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         className="bi bi-cart3" viewBox="0 0 16 16">
                        <path
                            d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                    </svg>
                    Корзина
                </a>
                <span className="badge ncart-badge">{dropdowncart.total_quantity}</span>
            </div>
            <div className="ncartdropdown-items">
                {error_message}
                {show_loader ? (
                    <div className="ncartdropdown-items-loader">
                        <div className="preloader pl-size-xs">
                            <div className="spinner-layer pl-red-grey">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (wrapped_nodes)}
                <hr/>
                {hasProducts &&
                    <div className="ncart-total">
                        <span className="total-title">Итого</span>
                        <span className="total-price">{dropdowncart.total}&nbsp;&#8381;</span>
                    </div>
                }
                <div className="ncart-button-block">
                    <a href="/shop/ncart/" className="button ncart-button  rounded-2">ПЕРЕЙТИ В КОРЗИНУ</a>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    dropdowncart: state.dropdowncart,
    dropdownitems: state.dropdownitems
})

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleState, fetchCartItems, fetchInCart, delCartItem, addToCart }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CartDropdownIcon)
