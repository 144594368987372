import * as types from '../costants/ActionTypes'
import axios from 'axios'
import {ADD_URL} from "../costants/Urls"


export const setInitialData = data => ({
  type: types.SET_INIT_STATE,
  data
});

export const setReadyState = () => ({
  type: types.SET_READY_STATE,
  status: "READY"
});

export const setLoadingState = () => ({
  type: types.SET_LOADING_STATE,
  status: "LOADING"
});

export const setErrorState = () => ({
  type: types.SET_ERROR_STATE,
  status: "ERROR"
});

export const setCartData = data => ({
    type: types.SET_SUCCESS_STATE,
    data
})

export const updateGlobalCartData = data => ({
    type: types.UPDATE_GLOBAL_CART,
    data
})


export const addToCart = productId =>  (dispatch, getState) => {

  dispatch(setLoadingState())

  const data = {"app": "product", "id": productId, "quantity": 1, "data": {}}
  const config = {
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        withCredentials: true
      }

  axios.post(ADD_URL, data, config).then( res => {

     if (res.status === 200 || res.status === 201){

         const data_for_send = {
             cart_item_id: res.data.cart_item.id,
             quantity: res.data.cart_item.quantity,
             max_quantity: res.data.cart_item.summary.max_quantity
         }

          dispatch(setCartData(data_for_send))
          dispatch(updateGlobalCartData(res.data))

        }else{

          dispatch(setErrorState())

        }
  }).catch(err=> {
    dispatch(setErrorState())

  })

};



/*

{
    "subtotal": "2280.12",
    "total": "2280.12",
    "total_quantity": 6,
    "num_items": 1,
    "extra_rows": [],
    "items": [
        {
            "id": 6,
            "sku": "84109",
            "title": "ПП №1301/8 лиственница белая ГЛЯНЕЦ 2800",
            "checked": true,
            "quantity": 6,
            "unit_step": "1.000",
            "unit": "шт",
            "unit_price": "380.020",
            "subtotal": "2280.120",
            "total": "2280.120",
            "item_app": "product",
            "created_at": "2021-08-03T09:13:02Z",
            "updated_at": "2021-08-04T19:15:04Z"
        }
    ]
}
 */

