import React, {useEffect} from 'react'


const CartDropdownProduct  = (props) => {
  const max_length = 26
  const short_title = (props.title.length > max_length) ? props.title.slice(0, max_length) + "..." : props.title
  const absolute_quantity = props.quantity
  const decimial_price =  parseFloat(props.total).toFixed(2)
  const link = props.link

  return (
      <div className="ncart-product-item">
        <span className="quantity">{absolute_quantity}</span>&nbsp;
        {link ?
            <span className="title text-truncate"><a href={link}>{short_title}</a></span>
            :
            <span className="title text-truncate">{short_title}</span>
        }
        <span className="del" onClick={props.delFunction}>×</span>
        <span className="price">{decimial_price}&nbsp;&#8381;</span>
      </div>
  )
}

export default CartDropdownProduct