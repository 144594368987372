import React from 'react'
import {Provider} from 'react-redux'
import CartDropdownApp from './containers/cartDropdownApp'


export default props => {
  const {store, attrs} = props;
  return (
    <Provider store={store}>
      <CartDropdownApp attrs={attrs}/>
    </Provider>
  )
}
