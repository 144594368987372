import React from 'react';
import {render} from 'react-dom';
import AddProductButton from './addproductbutton';
import {default as configureButtonStore} from "./addproductbutton/store/configureStore";

document.addEventListener("DOMContentLoaded", function(event) {
  const addProductButtonContainerList = document.querySelectorAll('.ncartaddproductbutton');
  addProductButtonContainerList.forEach(function(el, index){
    const buttonStore = configureButtonStore();
    let buttonAttrs = el.attributes;
    render(<AddProductButton store={buttonStore} attrs={buttonAttrs}/>, el);
  });


});

// enable hot module relaoding for the dev server
if (process.env.NODE_ENV === 'development')
  module.hot.accept();
