import * as types from '../costants/ActionTypes'
import axios from 'axios'
import {ITEMS_URL, INCART_URL, DEL_ITEMS_URL, ADD_URL} from "../costants/Urls"


export const setVisibleState = status => ({
  type: types.SET_VISIBLE_STATE,
  status
});

export const cartItemsLoading = () => ({
  type: types.SET_ITEMS_STATE,
  status: "LOADING"
});

export const setInCartState = status => ({
  type: types.SET_IN_CART_STATE,
  status
});

export const inCartLoaded = incart => ({
  type: types.SET_IN_CART_LOADED,
  incart
});

export const cartDataLoaded = data => ({
  type: types.SET_CART_DATA,
  data
});

export const cartItemsLoaded = items => ({
  type: types.SET_ITEMS_LOADED,
  items
});

export const cartItemsError = resp_data => ({
  type: types.SET_ITEMS_ERROR,
  payload: resp_data
});

export const removeCartItem = item_id => ({
  type: types.DELETE_ITEM,
  item_id: item_id
});

export const updateCartItem = cart_item =>({
    type: types.UPDATE_ITEM,
    cart_item
})

export const updateInCartItem = ({id, sku, quantity}) =>({
    type: types.UPDATE_IN_CART,
    id: id,
    sku: sku,
    quantity: quantity
})

const config = {
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    withCredentials: true
}


// Обновление incart корзины
export const fetchInCart = status =>  (dispatch, getState) => {

  const loading_state = getState().dropdowncart.incart_status

  if ((loading_state==="NOT_LOADED") || (loading_state==="ERROR")){

    dispatch(setInCartState("LOADING"));

    axios.get(INCART_URL, {})
      .then(res => {

        if (res.status === 200 || res.status === 201){

          dispatch(inCartLoaded(res.data.incart));

        }else{

          dispatch(setInCartState("ERROR"));

        }

      })
      .catch(err => {

        dispatch(setInCartState("ERROR"));

      });
  }

};

// Обновление данных элементов корзины
export const fetchCartItems = status =>  (dispatch, getState) => {

  const loading_state = getState()

  if ((loading_state.dropdownitems.status!=="LOADING")&&(!loading_state.dropdownitems.fetched) ){

    dispatch(cartItemsLoading());

    axios.get(ITEMS_URL, {})
      .then(res => {

          if (res.status === 200 || res.status === 201){

              dispatch(cartDataLoaded(res.data));
              dispatch(cartItemsLoaded(res.data.items));

          }else{

            dispatch(cartItemsError("Ошибка загрузки данных"));

          }

      })
      .catch(err => {

          dispatch(cartItemsError(err.message));

      });
  }

};

// Удаление элемента корзины
export const delCartItem = productId => (dispatch, getState) => {

  const prev_state = getState()
  const productItem = prev_state.dropdownitems.items.find(x => x.id === productId)

  if (productItem !== undefined) {

      dispatch(delCartItemUnsafe(productItem))

  }

}


export const delCartItemUnsafe = productItem =>  (dispatch, getState) => {

  const url = DEL_ITEMS_URL + productItem.id + '/'

  dispatch(cartItemsLoading());

  axios.delete(url, config).then( res => {

     if (res.status === 200 || res.status === 201){

          dispatch(cartDataLoaded(res.data))
          dispatch(updateInCartItem({id: productItem.id, sku: productItem.sku, quantity: 0}))
          dispatch(removeCartItem(productItem.id))

     }else{

         dispatch(cartItemsError("Ошибка загрузки данных"))

     }

  }).catch(err=> {

    dispatch(cartItemsError(err.message))

  })

};


export const addToCart = data =>  (dispatch, getState) => {
    //const data = {"app": "product", "id": productId, "quantity": 1, "data": {}}

    const loading_state = getState().dropdownitems.status
    if (loading_state!=="LOADING"){

        dispatch(cartItemsLoading());

        axios.post(ADD_URL, data, config).then( res => {

            if (res.status === 200 || res.status === 201){

                if ('cart_item' in res.data){
                    dispatch(updateInCartItem(res.data.cart_item));
                    dispatch(updateCartItem(res.data.cart_item));
                    dispatch(cartDataLoaded(res.data));
                }else{
                    dispatch(cartItemsLoaded(res.data.cart.items));
                    dispatch(cartDataLoaded(res.data.cart));
                }

            }else{

                dispatch(cartItemsError());

            }

        }).catch(err=> {

            dispatch(cartItemsError());

        })

  }
};

//http://tdpra.ru:8879/shop/api/ncart/add/
/*

{
  "cart_item": {
    "id": 11,
    "summary": {
      "link": "/shop/products/product/polkoderzhatel-odn733ce0e964f80302d37a3faff5bc73e0/",
      "estimated_delivery": "",
      "enabled": true,
      "enabled_help_text": "",
      "html": "",
      "price_per_step": false,
      "max_quantity": 100,
      "valid_until": ""
    },
    "extra_rows": [],
    "sku": "86172",
    "title": "Полкодержатель односторонний, хром/черный, ART.4 CR",
    "image": null,
    "checked": true,
    "quantity": 2,
    "unit_step": "1.000",
    "unit": "компл",
    "unit_price": "59.230",
    "subtotal": "118.460",
    "total": "118.460",
    "item_app": "product",
    "created_at": "2021-08-17T18:28:00.903549Z",
    "updated_at": "2021-08-17T18:28:00.904815Z"
  },
  "cart": {
    "subtotal": "352.52",
    "total": "352.52",
    "total_quantity": 9,
    "num_items": 3,
    "extra_rows": []
  }
}
*/
/*
{
  "subtotal": "234.06",
  "total": "234.06",
  "total_quantity": 7,
  "num_items": 2,
  "extra_rows": []
}

{
    "subtotal": "2280.12",
    "total": "2280.12",
    "total_quantity": 6,
    "num_items": 1,
    "extra_rows": [],
    "items": [
        {
            "id": 6,
            "sku": "84109",
            "title": "ПП №1301/8 лиственница белая ГЛЯНЕЦ 2800",
            "checked": true,
            "quantity": 6,
            "unit_step": "1.000",
            "unit": "шт",
            "unit_price": "380.020",
            "subtotal": "2280.120",
            "total": "2280.120",
            "item_app": "product",
            "created_at": "2021-08-03T09:13:02Z",
            "updated_at": "2021-08-04T19:15:04Z"
        }
    ]
}
 */