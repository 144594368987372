import { combineReducers } from 'redux'

import {SET_INIT_STATE, SET_READY_STATE, SET_LOADING_STATE,
  SET_ERROR_STATE, SET_SUCCESS_STATE, UPDATE_GLOBAL_CART} from "../costants/ActionTypes";

const initialState = {
  status: "READY",
  //product_id: null,
  quantity: 0,
  max_quantity: 0,
  cart_item_id: null,
  cart_data: null
}


const addbutton = (state = initialState, action) => {
  switch (action.type) {
    case SET_INIT_STATE:

      return Object.assign({}, state, action.data)

    case SET_READY_STATE:

      return Object.assign({}, state, {
        status: "READY"
      })

    case SET_LOADING_STATE:

      return Object.assign({}, state, {
        status: "LOADING"
      })

    case SET_ERROR_STATE:
      return Object.assign({}, state, {
        status: "ERROR"
      })

    case SET_SUCCESS_STATE:
      return Object.assign({}, state, {
        status: initialState.status,
        cart_item_id: action.data.cart_item_id,
        quantity: action.data.quantity,
        max_quantity: action.data.max_quantity
      })

    case UPDATE_GLOBAL_CART:
      return Object.assign({}, state, {
        cart_data: action.data
      })


    default:
      return state

  }
}

export default combineReducers({
  addbutton
})