import React, {useState, useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ActionCreators from '../actions'

let instanceCart

const AddProductButton = props => {

  const {attrs, addbutton} = props
  const product_id = attrs.getNamedItem('data-product-id').value
  const product_step = attrs.getNamedItem('data-product-step') ? parseFloat(attrs.getNamedItem('data-product-step').value) : 1.0
  const product_unit = attrs.getNamedItem('data-product-unit') ? attrs.getNamedItem('data-product-unit').value : "шт"
  const product_complect = attrs.getNamedItem('data-product-complect') ? true : false
  const button_size = attrs.getNamedItem('data-size') ? attrs.getNamedItem('data-size').value : "big"
  const [add_quantity, setAddQuantity] = useState(1)
  const [add_real_quantity, setAddRealQuantity] = useState(product_step)
  const button_enabled = (add_quantity !== '') && (addbutton.max_quantity >= addbutton.quantity + add_quantity) && (addbutton.status === "READY")
  const minus_enabled = add_quantity > 1 ? true : false
  const plus_enabled = ((add_quantity !== '') && (addbutton.max_quantity > addbutton.quantity + add_quantity + 1)) ? true : false
  const quantity_enabled = true

  let className = 'ncart-add-button '+ button_size;
  className = (button_enabled) ? (className += ' enabled') : (className += ' disabled');

  const addToCart = () => {
    if((instanceCart)&&(button_enabled)){

        let addEvent = new CustomEvent("NcartAddItem", {detail: {"app": "product", "id": product_id, "quantity": add_quantity, "data": {}}});
        document.dispatchEvent(addEvent);
        if(product_complect){
            props.setInitialData({quantity: addbutton.quantity + add_quantity});
        }
    }
    // todo: loader
  };


  const handleChangeQuantity = real_quantity => {

      if (real_quantity !== ''){
          if (real_quantity.includes(",")){
              real_quantity = real_quantity.replace(",", ".")
          }
      }
      setAddRealQuantity(real_quantity)

  };

  const updateAddQuantity = quantity => {

      if (addbutton.max_quantity >= addbutton.quantity + quantity){
          setAddQuantity(quantity)
          setAddRealQuantity(product_step * quantity)

      }else{
          let dq = addbutton.max_quantity - addbutton.quantity - 1
          setAddQuantity(dq > 0 ? dq : 1)
          setAddRealQuantity(dq > 0 ? product_step * dq : product_step)
      }
  };

  const handleApplayQuantity = e => {

      let real_result = add_real_quantity ? add_real_quantity: product_step,
          result = 0

      if (product_step===1.0){
          result = parseInt(real_result);
      }else{
          result = Math.trunc(parseFloat(real_result) / product_step);
      }

      result = result < 1 ? 1 : result

      if (addbutton.max_quantity >= addbutton.quantity + result){
          setAddQuantity(result)
          setAddRealQuantity(product_step * result)
        }else{
          let dq = addbutton.max_quantity - addbutton.quantity - 1
          setAddQuantity(dq > 0 ? dq : 1)
          setAddRealQuantity(dq > 0 ? product_step * dq : product_step)
        }

  };

  const handleDaltaQuantity = (e, delta) => {
      updateAddQuantity(add_quantity + delta)
  };

  useEffect(() => {
      const in_stock = parseInt(attrs.getNamedItem('data-in-stock').value)
      props.setInitialData({max_quantity: in_stock})

      function handleUpdateInCart(event) {
          let allitems = event.detail || [];
          let current_product = allitems.find(function(element){
              return element.sku === product_id
          });

          if(current_product){
              props.setInitialData({quantity: current_product.quantity});
          }else{
              props.setInitialData({quantity: 0});
          }
      }

      document.addEventListener("NcartInCartUpdated", handleUpdateInCart);

      const getCartData = () => {

          const interval = setInterval(() => {
              instanceCart = window._global_singleton_instance["ncart"];
              if (instanceCart) {
                  clearInterval(interval);
              }
              }, 1000);

      return () => {
          document.removeEventListener("NcartInCartUpdated", handleUpdateInCart);
      };

    };

    getCartData();

  }, []);

  return (
      <div>
      {button_size === "big" && (
          <div className="ncart-item-quantity text-center">
                  { minus_enabled ?
                      <div className="nquantity-control nquantity-down" onClick={e => handleDaltaQuantity(e, -1)}>
                        <i className="material-icons">remove</i>
                      </div>
                      :
                      <div className="nquantity-control nquantity-down disabled">
                        <i className="material-icons">remove</i>
                      </div>
                  }
                  { quantity_enabled ?
                      <div className="ncart-form-line">
                          <input type="text"
                                 className={`ncart-form-control${true ? '': ' error-text'}`}
                                 onBlur={e => handleApplayQuantity(e)}
                                 onChange={e => handleChangeQuantity(e.currentTarget.value)}
                                 onKeyDown={e => e.key === 'Enter' && handleApplayQuantity(e)}
                                 value={add_real_quantity}/>
                      </div>

                      :

                      <div className="ncart-form-line">
                          <input type="text" className="ncart-form-control disabled" value={add_quantity} readOnly={true}/>
                      </div>
                  }

                  {plus_enabled ?
                      <div className="nquantity-control nquantity-up" onClick={e => handleDaltaQuantity(e, 1)}>
                          <i className="material-icons">add</i>
                      </div>
                      :
                      <div className="nquantity-control nquantity-up disabled">
                          <i className="material-icons">add</i>
                      </div>
                  }
              </div>
      )}
      {button_size === "big" && (
          <div className="ncart-item-unit">{product_unit}</div>
      )}
      <button className={className} onClick={() => addToCart()}>
        { addbutton.status === "LOADING" ? (
          <div className="ncart-add-button-loader">
          <div className="preloader pl-size-xs">
            <div className="spinner-layer pl-red-grey">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
        ): (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
            </svg>
        ) }
        {button_size === "big" && <span className="ncart-add-button-title">Добавить в корзину</span>}
        {addbutton.quantity > 0 ? (<span className="ncart-add-button-badge">{addbutton.quantity}</span>): null }
      </button>
      </div>
  )
};


const mapStateToProps = state => ({
  addbutton: state.addbutton
});

const mapDispatchToProps = dispatch => bindActionCreators(ActionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddProductButton);