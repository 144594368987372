export const SET_VISIBLE_STATE = 'SET_VISIBLE_STATE';
export const SET_CART_DATA = 'SET_CART_DATA';
export const SET_IN_CART_STATE = 'SET_IN_CART_STATE';
export const SET_IN_CART_LOADED = 'SET_IN_CART_LOADED';
export const UPDATE_IN_CART = 'UPDATE_IN_CART';

export const SET_ITEMS_STATE = 'SET_ITEMS_STATE';
export const SET_ITEMS_LOADED = 'SET_ITEMS_LOADED';
export const SET_ITEMS_ERROR = 'SET_ITEMS_ERROR';
export const DELETE_ITEM = 'DELETE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';