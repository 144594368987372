import {SET_VISIBLE_STATE, SET_CART_DATA, SET_IN_CART_STATE, SET_IN_CART_LOADED, UPDATE_IN_CART} from '../costants/ActionTypes'

const initialState = {
  status: "CLOSED",
  incart_status: "NOT_LOADED",
  subtotal: "0",
  total: "0",
  total_quantity: 0,
  num_items: 0,
  incart: []
}


const dropdowncart = (state = initialState, action) => {
  switch (action.type) {

    case SET_VISIBLE_STATE:

      return Object.assign({}, state, {
        status: action.status
      })

    case SET_IN_CART_STATE:
      return Object.assign({}, state, {
        incart_status: action.status
      })

    case SET_IN_CART_LOADED:
      let total_quantity = action.incart.reduce(function(previousValue, currentValue) {
        return previousValue + currentValue.quantity;
      }, 0);

      return Object.assign({}, state, {
        incart_status: "LOADED",
        total_quantity: total_quantity,
        incart: [...action.incart]
      })

    case UPDATE_IN_CART:

      let updated_items = [...state.incart];

      let current_item = updated_items.find(function(element){
        return element.id === action.id
      });

      if (current_item){
        Object.assign(current_item, {quantity: action.quantity})
      }else{
        updated_items.push({id: action.id, sku: action.sku, quantity: action.quantity})
      }
      return Object.assign({}, state, {
        //status: "LOADED",
        incart: [...updated_items]
      })

    case SET_CART_DATA:

      return Object.assign({}, state, {
        subtotal: action.data.subtotal,
        total: action.data.total,
        total_quantity: action.data.total_quantity,
        num_items: action.data.num_items
      })

      return state

    default:
      return state

  }
}

export default dropdowncart