import React from 'react'
import { connect } from 'react-redux'


const CartMobileIcon  = props => {

    const { total_quantity } = props;

    return (
        total_quantity > 0 &&
        <div>
            <span className="badge cart-badge">{total_quantity}</span>
            <a href="/shop/ncart/" type="button" className="mobile-button">
                <i className="material-icons mobile-icon">shopping_cart</i>
            </a>
        </div>
    )
}

const mapStateToProps = state => ({
    total_quantity: state.dropdowncart.total_quantity
})

export default connect(
    mapStateToProps
)(CartMobileIcon)
