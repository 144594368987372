import {
  SET_ITEMS_STATE,
  SET_ITEMS_ERROR,
  SET_ITEMS_LOADED,
  DELETE_ITEM,
  UPDATE_ITEM,
} from '../costants/ActionTypes'

const initialState = {
  status: "NOT_LOADED",
  fetched: false,
  items: []
}


const dropdownitems = (state = initialState, action) => {
  switch (action.type) {

    case SET_ITEMS_STATE:

      return Object.assign({}, state, {
        status: action.status
      })

    case SET_ITEMS_ERROR:

      return Object.assign({}, state, {
        status: "ERROR"
      })

    case SET_ITEMS_LOADED:

      return Object.assign({}, state, {
        status: "LOADED",
        fetched: true,
        items: [...action.items]
      })

    case UPDATE_ITEM:
      let updated_items = [...state.items];
      let element_id = action.cart_item.id
      let current_item = updated_items.find(function(element){
        return element.id === element_id
      });

      if (current_item){
        Object.assign(current_item, action.cart_item)
      }else{
        updated_items.push(action.cart_item)
      }

      return Object.assign({}, state, {
        status: "LOADED",
        items: [...updated_items]
      })

    case DELETE_ITEM:

      const new_items = state.items.filter((element) => {return element.id !== action.item_id})

      return Object.assign({}, state, {
        status: "LOADED",
        items: [...new_items]
      })

    default:
      return state

  }
}


export default dropdownitems